<template src="./bankIntegration.html"></template>
<style src="./bankIntegration.scss" lang="scss" scoped></style>

<script>
import BankLogo from '@/components/BankLogo'
import { getBankAuthorizationUrl } from '@/api/openBankingReadWrite'
import { LSKeys } from '@/utils/constants'

export default {
  name: 'BankIntegration',
  components: {
    BankLogo
  },
  data() {
    return {
      currentStep: 0,
      sideFAQs: [
        {
          iconName: 'cog',
          headerText: 'How does this work?',
          html:
            'You are now connecting through <b>Swoop</b>: a secure, FCA authorised service that acts with your bank.<br><br>Thousands of users already used this interface to securely share their bank data.'
        },
        {
          iconName: 'lock',
          headerText: 'Secure Connection',
          html:
            'Your security is important to us.<br><br>Swoop gets <b>read-only</b> access to your online bank account and uses <b>bank grade encryption</b> to enable access to your account.'
        },
        {
          iconName: 'shield',
          headerText: 'FCA Authorised',
          html:
            '<a>Swoop is an authorised</a> payment institution by the Financial Conduct Authority to provide Account Information Services (AIS) and Payment Initiation Services (PIS) in the UK.<br><br> License Number: &nbsp;<a href="https://register.fca.org.uk/ShPo_FirmDetailsPage?id=0010X00004J8HEyQAN" target="_blank" rel="noopener noreferrer">826414</a>.'
        }
      ],
      selectedSideFAQIndex: null,
      permissions: [
        {
          title: 'Your Personal Information',
          text: 'We will retrieve your Full Name, Date of Birth, Address, Email and Phone Number.'
        },
        {
          title: 'Your Account(s)',
          text: 'We will retrieve a list of your current and saving accounts.'
        },
        {
          title: 'Your Transactions',
          text: "We will retrieve your account transactions' amount and details."
        },
        {
          title: 'Your Balance',
          text: "We will retrieve your account's current balance."
        },
        {
          title: 'Your Card(s)',
          text: "We will retrieve your card's info, balance, transactions, and details."
        }
      ],
      selectedPermissionIndex: null,
      banks: [
        {
          bankName: 'forgerock',
          fullBankName: 'forgerock',
          redirectLink: ''
        },
        {
          bankName: 'ozoneBank',
          fullBankName: 'ozone',
          redirectLink: ''
        }
      ]
    }
  },
  methods: {
    moveToStep(n) {
      switch (n) {
        case 1:
          this.currentStep = 1
          break
        case 2:
          this.currentStep = 2
          break
      }
    },
    cancelBtnClickHandler() {
      this.toPreviousRoute()
    },
    sideFAQClickHandler(index) {
      this.selectedSideFAQIndex = index === this.selectedSideFAQIndex ? null : index
    },
    permissionClickHandler(index) {
      this.selectedPermissionIndex = index === this.selectedPermissionIndex ? null : index
    },
    getPrevRoute() {
      const route = JSON.parse(localStorage.getItem(LSKeys.bankIntegrationPrevRoute)) || { name: 'dashboard' }
      // On cancelation should be returned to business journey step instead of Open Banking journey step
      return route.name === 'journey-open-banking' ? 'journey-business' : route
    },
    toPreviousRoute() {
      this.$router.push(this.getPrevRoute())
    },
    async bankRedirectClickHandler(bank) {
      this.$set(bank, 'isLoading', true)
      try {
        const res = await getBankAuthorizationUrl(bank.bankName)
        bank.isLoading = false
        if (res.data.url) {
          this.saveBankName(bank.bankName)
          window.location = res.data.url
        }
      } catch (error) {
        console.error(error)
        bank.isLoading = false
      }
    },
    saveBankName(bankName) {
      try {
        localStorage.setItem(LSKeys.bankIntegrationBankName, bankName)
      } catch (e) {
        console.error('No access to Local Storage')
      }
    }
  },
  created() {
    this.moveToStep(1)
  }
}
</script>
